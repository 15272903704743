// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    brand: {},
    brands: [],
    count: 0,
};

const slice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET BRANDS
        getBrandsSuccess(state, action) {
            state.brands = action.payload.brandList;
            state.count = action.payload.total;
        },

        // GET BRAND
        getBrandSuccess(state, action) {
            state.brand = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBrands(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/brand/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getBrandsSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addBrand(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/brand/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.brand.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBrand(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/brand/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getBrandSuccess(response.data.brand));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBrand(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/brand/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getBrandSuccess(response.data.brand));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
