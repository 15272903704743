// third-party
import { stringify } from 'qs';
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    dynamicData: {},
    logisticItems: [],
    tncs: [],
};

const slice = createSlice({
    name: 'dynamicData',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET DynamicData
        getDynamicDataSuccess(state, action) {
            state.dynamicData = action.payload.dynamicDataMap;
        },

        // GET Logistic Items
        getLogisticItemsSuccess(state, action) {
            state.logisticItems = action.payload.logisticItemList;
        },

        // GET TNC List
        getTncsSuccess(state, action) {
            state.tncs = action.payload.tncList;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLogisticItemList(callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/base-data/logistic`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getLogisticItemsSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTncList(callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/base-data/tnc`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getTncsSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDynamicData(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/base-data/id-name?${stringify(data)}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getDynamicDataSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
