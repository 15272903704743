// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    quotation: {},
    quotations: [],
    count: 0,
};

const slice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET SERIES LIST
        getQuotationsSuccess(state, action) {
            state.quotations = action.payload.quotationList;
            state.count = action.payload.total;
        },

        // GET SERIES
        getQuotationSuccess(state, action) {
            state.quotation = action.payload;
        },

        clearQuotationSuccess(state) {
            state.quotation = {};
        },

        // ADD ITEM
        addQuotationItemSuccess(state, action) {
            if(Array.isArray(state.quotation.quotationItemList)) {
                state.quotation.quotationItemList.push(action.payload);
            } else {
                state.quotation.quotationItemList = [action.payload];
            }
        },
        // UPDATE ITEM
        updateQuotationItemSuccess(state, action) {
            state.quotation.quotationItemList = state.quotation.quotationItemList.map((row) => (row.id === action.payload.id ? action.payload : row));
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuotations(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/quotation/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getQuotationsSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearQuotation() {
    return async () => {
        dispatch(slice.actions.clearQuotationSuccess());
    };
}

export function addQuotation(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/quotation/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.quotation.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getQuotation(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/quotation/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getQuotationSuccess(response.data.quotation));
            
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateQuotation(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/quotation/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getQuotationSuccess(response.data.quotation));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addQuotationItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/quotation/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.addQuotationItemSuccess(response.data.quotationItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateQuotationItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/quotation/item/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateQuotationItemSuccess(response.data.quotationItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateQuotationItemStatus(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/quotation/item/status-update', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateQuotationItemSuccess(response.data.quotationItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}