// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    emailTemplate: {},
    emailTemplates: [],
    count: 0,
};

const slice = createSlice({
    name: 'emailTemplate',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET EMAIL TEMPLATES
        getEmailTemplatesSuccess(state, action) {
            state.emailTemplates = action.payload.emailTemplateList;
            state.count = action.payload.total;
        },

        // GET EMAIL TEMPLATE
        getEmailTemplateSuccess(state, action) {
            state.emailTemplate = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEmailTemplates(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/email-template/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getEmailTemplatesSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEmailTemplate(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/email-template/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getEmailTemplateSuccess(response.data.emailTemplate));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateEmailTemplate(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/email-template/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getEmailTemplateSuccess(response.data.emailTemplate));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function testEmailTemplate(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/email-template/test', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
