// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    invoice: {},
    invoices: [],
    count: 0,
};

const slice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET SERIES LIST
        getInvoicesSuccess(state, action) {
            state.invoices = action.payload.invoiceList;
            state.count = action.payload.total;
        },

        // GET SERIES
        getInvoiceSuccess(state, action) {
            state.invoice = action.payload;
        },

        clearInvoiceSuccess(state) {
            state.invoice = {};
        },

        // ADD ITEM
        addInvoiceItemSuccess(state, action) {
            if(Array.isArray(state.invoice.invoiceItemList)) {
                state.invoice.invoiceItemList.push(action.payload);
            } else {
                state.invoice.invoiceItemList = [action.payload];
            }
        },
        // UPDATE ITEM
        updateInvoiceItemSuccess(state, action) {
            state.invoice.invoiceItemList = state.invoice.invoiceItemList.map((row) => (row.id === action.payload.id ? action.payload : row));
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoices(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/invoice/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getInvoicesSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearInvoice() {
    return async () => {
        dispatch(slice.actions.clearInvoiceSuccess());
    };
}

export function addInvoice(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/invoice/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.invoice.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoice(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/invoice/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getInvoiceSuccess(response.data.invoice));
            
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoice(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/invoice/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getInvoiceSuccess(response.data.invoice));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addInvoiceItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/invoice/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.addInvoiceItemSuccess(response.data.invoiceItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoiceItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/invoice/item/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateInvoiceItemSuccess(response.data.invoiceItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateInvoiceItemStatus(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/invoice/item/status-update', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateInvoiceItemSuccess(response.data.invoiceItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}