// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    projectLead: {},
    projectLeads: [],
    count: 0,
};

const slice = createSlice({
    name: 'projectLead',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET SERIES LIST
        getProjectLeadsSuccess(state, action) {
            state.projectLeads = action.payload.projectLeadList;
            state.count = action.payload.total;
        },

        // GET SERIES
        getProjectLeadSuccess(state, action) {
            state.projectLead = action.payload;
        },

        // ADD ITEM
        addProjectLeadItemSuccess(state, action) {
            if(Array.isArray(state.projectLead.projectItemList)) {
                state.projectLead.projectItemList.push(action.payload);
            } else {
                state.projectLead.projectItemList = [action.payload];
            }
        },
        // UPDATE ITEM
        updateProjectLeadItemSuccess(state, action) {
            state.projectLead.projectItemList = state.projectLead.projectItemList.map((row) => (row.id === action.payload.id ? action.payload : row));
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProjectLeads(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/project/lead/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getProjectLeadsSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProjectLead(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/project/lead/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.projectLead.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjectLead(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/project/lead/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getProjectLeadSuccess(response.data.projectLead));
            if(callback){
                callback(response.data.projectLead);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProjectLead(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/project/lead/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getProjectLeadSuccess(response.data.projectLead));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProjectItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/project/item/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.addProjectLeadItemSuccess(response.data.projectItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProjectItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/project/item/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateProjectLeadItemSuccess(response.data.projectItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProjectItemStatus(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/project/item/status-update', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateProjectLeadItemSuccess(response.data.projectItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}