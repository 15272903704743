// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    series: {},
    seriesList: [],
    count: 0,
};

const slice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET SERIES LIST
        getSeriesListSuccess(state, action) {
            state.seriesList = action.payload.seriesList;
            state.count = action.payload.total;
        },

        // GET SERIES
        getSeriesSuccess(state, action) {
            state.series = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSeriesList(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/series/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getSeriesListSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addSeries(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/series/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.series.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSeries(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/series/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getSeriesSuccess(response.data.series));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateSeries(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/series/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getSeriesSuccess(response.data.series));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
