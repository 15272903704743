// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    deliveryOrder: {},
    deliveryOrders: [],
    count: 0,
};

const slice = createSlice({
    name: 'deliveryOrder',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET SERIES LIST
        getDeliveryOrdersSuccess(state, action) {
            state.deliveryOrders = action.payload.deliveryOrderList;
            state.count = action.payload.total;
        },

        // GET SERIES
        getDeliveryOrderSuccess(state, action) {
            state.deliveryOrder = action.payload;
        },

        clearDeliveryOrderSuccess(state) {
            state.deliveryOrder = {};
        },

        // ADD ITEM
        addDeliveryItemSuccess(state, action) {
            if(Array.isArray(state.deliveryOrder.deliveryItemList)) {
                state.deliveryOrder.deliveryItemList.push(action.payload);
            } else {
                state.deliveryOrder.deliveryItemList = [action.payload];
            }
        },
        // UPDATE ITEM
        updateDeliveryItemSuccess(state, action) {
            state.deliveryOrder.deliveryItemList = state.deliveryOrder.deliveryItemList.map((row) => (row.id === action.payload.id ? action.payload : row));
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDeliveryOrders(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/delivery/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getDeliveryOrdersSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function clearDeliveryOrder() {
    return async () => {
        dispatch(slice.actions.clearDeliveryOrderSuccess());
    };
}

export function addDeliveryOrder(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/delivery/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.deliveryOrder.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDeliveryOrder(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/delivery/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getDeliveryOrderSuccess(response.data.deliveryOrder));
            
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateDeliveryOrder(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/delivery/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getDeliveryOrderSuccess(response.data.deliveryOrder));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addDeliveryItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/delivery/item/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.addDeliveryItemSuccess(response.data.deliveryItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateDeliveryItem(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/delivery/item/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateDeliveryItemSuccess(response.data.deliveryItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateDeliveryItemStatus(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/delivery/item/status-update', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.updateDeliveryItemSuccess(response.data.deliveryItem));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}