import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false
};

// ==============================|| SLICE - BACKDROP ||============================== //

const backdrop = createSlice({
    name: 'backdrop',
    initialState,
    reducers: {
        openBackdrop(state) {
            state.open = true;
        },

        closeBackdrop(state) {
            state.open = false;
        }
    }
});

export default backdrop.reducer;

export const { closeBackdrop, openBackdrop } = backdrop.actions;
