// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    count: 0,
    users: [],
    user: {},
    products: [],
    productreviews: []
};

const slice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CLIENTS
        getUsersSuccess(state, action) {
            state.users = action.payload.userProfileList;
            state.count = action.payload.total;
        },

        // GET CLIENT
        getUserSuccess(state, action) {
            console.log(action.payload)
            state.user = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getUsersSuccess(response.data));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addUser(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            if(callback){
                callback(response.data.user.id);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUser(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/user/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            
            dispatch(slice.actions.getUserSuccess(response.data.userProfile));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

}

export function updateUser(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getUserSuccess(response.data.userProfile));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changePassword(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/password/change', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reconfirm(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/user/reconfirm/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
