// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUser, IconBasket, IconLayoutKanban, IconMail } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconLayoutKanban,
    IconBasket,
    IconMail
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const crm = {
    id: 'crm',
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/user/list',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'product',
            title: <FormattedMessage id="product" />,
            type: 'collapse',
            icon: icons.IconBasket,
            children: [
                {
                    id: 'brand-list',
                    title: <FormattedMessage id="brand-list" />,
                    type: 'item',
                    url: '/brand/list',
                    breadcrumbs: false
                },
                {
                    id: 'series-list',
                    title: <FormattedMessage id="series-list" />,
                    type: 'item',
                    url: '/series/list',
                    breadcrumbs: false
                },
                {
                    id: 'product-list',
                    title: <FormattedMessage id="product-list" />,
                    type: 'item',
                    url: '/product/list',
                    breadcrumbs: false
                },
                {
                    id: 'product-import',
                    title: <FormattedMessage id="product-import" />,
                    type: 'item',
                    url: '/product/import',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'email-template',
            title: <FormattedMessage id="email-template" />,
            type: 'item',
            url: '/email-template/list',
            icon: icons.IconMail,
            breadcrumbs: false
        },
        {
            id: 'project-leads',
            title: <FormattedMessage id="project-leads" />,
            type: 'item',
            url: '/project-lead/list',
            icon: icons.IconLayoutKanban,
            breadcrumbs: false
        }
    ]
};

export default crm;
