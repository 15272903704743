// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    firm: {},
    firmMap: {
        design_firm: [],
        architect_firm: [],
        developer_firm: [],
        contractor_firm: [],
    },
    firms: [],
    count: 0,
};

const slice = createSlice({
    name: 'firm',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET FIRM MAP
        getFirmMapSuccess(state, action) {
            state.firmMap = action.payload.firmMap;
        },

        // GET FIRMS
        getFirmsSuccess(state, action) {
            state.firms = action.payload.firmList;
            state.count = action.payload.total;
        },

        // GET FIRM
        getFirmSuccess(state, action) {
            state.firm = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFirmMap(callback) {
    return async () => {
        try {
            const response = await axios.get('/api/firm/map',
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getFirmMapSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFirms(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/firm/list', data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getFirmsSuccess(response.data));
            if(callback){
                callback(); 
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addFirm(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/firm/add', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }
            );
            dispatch(slice.actions.getFirmMapSuccess(response.data));
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFirm(id, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/firm/details/'+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getFirmSuccess(response.data.firm));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateFirm(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/firm/edit', data, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            dispatch(slice.actions.getFirmSuccess(response.data.firm));
            if(callback){
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
