// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import backdropReducer from './slices/backdrop';
import snackbarReducer from './slices/snackbar';
import dynamicDataReducer from './slices/dynamic-data';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import brandReducer from './slices/brand';
import seriesReducer from './slices/series';
import productReducer from './slices/product';
import projectLeadReducer from './slices/project-lead';
import quotationReducer from './slices/quotation';
import invoiceReducer from './slices/invoice';
import deliveryOrderReducer from './slices/deliveryOrder';
import emailTemplateReducer from './slices/email-template';
import firmReducer from './slices/firm';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import accountReducer from './slices/account';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    backdrop: backdropReducer,
    dynamicData: dynamicDataReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contact: contactReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    mail: mailReducer,
    user: userReducer,
    account: accountReducer,
    brand: brandReducer,
    series: seriesReducer,
    product: productReducer,
    projectLead: projectLeadReducer,
    quotation: quotationReducer,
    invoice: invoiceReducer,
    deliveryOrder: deliveryOrderReducer,
    emailTemplate: emailTemplateReducer,
    firm: firmReducer,
    menu: menuReducer
});

export default reducer;
